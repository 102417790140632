import { concat } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import PlatformConditional from '../../../components/PlatformConditional';
import { formatPartialDate, isPartialDate } from 'neuro-utils';
import {
  fieldNameToCodeString,
  getLatestMeasurementValueAndDate,
  getLatestValue,
  getOngoingDrivingBan,
  isOngoingTimespan,
} from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { useAppSelector as useSelector } from 'Store/index';

const fm = (id: string, values?: { [key: string]: string }): JSX.Element => (
  <FormattedMessage id={id} values={values || {}} />
);

const chooseBetweenWeightAndBmi = (
  weight: { value?: string; date?: PartialDate } | null,
  bmi: { value?: string; date?: PartialDate } | null,
): Array<string> => {
  return (weight?.date?.[0] ?? 0) >= (bmi?.date?.[0] ?? 0) &&
    (weight?.date?.[1] ?? 0) >= (bmi?.date?.[1] ?? 0) &&
    (weight?.date?.[2] ?? 0) >= (bmi?.date?.[2] ?? 0)
    ? ['weight']
    : bmi
      ? ['bmi']
      : [];
};

/**
 * Get localized data to render, or "No data" text if undefined.
 * @param key Localization key's significant part, e.g. `"maritalStatus"`
 * @param value Value to render in tile.
 * @returns Element containing localized data or "no data".
 */
const getLocalizedValue = (key: string, value: string | string[] | undefined): JSX.Element => {
  if (!value || (Array.isArray(value) && value.length === 0)) return fm('general.noData');
  const prefix = `background.opts.${key}.`;
  if (Array.isArray(value) && value.length > 1) return fm('background.multiple');
  else return fm(prefix + value);
};

/**
 * Value field of TitleValue for drivingbans (For documents that use drivingBanR1s and drivingBanR2s)
 * @param documents Background documents
 * @returns JSX.Element
 */
const checkDrivingBans = (documents: IBackground[]): JSX.Element => {
  return (
    <>
      {documents &&
      documents[0].driversLicenseGroup &&
      documents[0].driversLicenseGroup.includes('R1') &&
      documents[0].drivingBanR1s &&
      getOngoingDrivingBan(documents[0].drivingBanR1s) ? (
        <div>
          {fm('background.drivingBanR1Short')} {fm('background.fromShort')}{' '}
          {getOngoingDrivingBan(documents[0].drivingBanR1s)?.startDate
            ? formatPartialDate(getOngoingDrivingBan(documents[0].drivingBanR1s)?.startDate)
            : '-'}
        </div>
      ) : (
        ''
      )}
      {documents &&
      documents[0].driversLicenseGroup &&
      documents[0].driversLicenseGroup.includes('R2') &&
      documents[0].drivingBanR2s &&
      getOngoingDrivingBan(documents[0].drivingBanR2s) ? (
        <div>
          {fm('background.drivingBanR2Short')} {fm('background.fromShort')}{' '}
          {getOngoingDrivingBan(documents[0].drivingBanR2s)?.startDate
            ? formatPartialDate(getOngoingDrivingBan(documents[0].drivingBanR2s)?.startDate)
            : '-'}
        </div>
      ) : (
        ''
      )}
      {documents &&
      ((!documents[0].drivingBanR1s && !documents[0].drivingBanR2s) ||
        (documents[0].drivingBanR1s &&
          documents[0].drivingBanR1s?.length < 1 &&
          (!documents[0].drivingBanR2s || (documents[0].drivingBanR2s && documents[0].drivingBanR2s.length < 1))) ||
        (documents[0].drivingBanR2s &&
          documents[0].drivingBanR2s?.length < 1 &&
          (!documents[0].drivingBanR1s || (documents[0].drivingBanR1s && documents[0].drivingBanR1s.length < 1))))
        ? fm('general.noData')
        : documents &&
            ((documents[0].drivingBanR1s &&
              documents[0].drivingBanR1s.length > 0 &&
              getOngoingDrivingBan(documents[0].drivingBanR1s) === undefined &&
              ((documents[0].drivingBanR2s &&
                documents[0].drivingBanR2s.length > 0 &&
                getOngoingDrivingBan(documents[0].drivingBanR2s) === undefined) ||
                !documents[0].drivingBanR2s ||
                documents[0].drivingBanR2s.length < 1)) ||
              (documents[0].drivingBanR2s &&
                documents[0].drivingBanR2s.length > 0 &&
                getOngoingDrivingBan(documents[0].drivingBanR2s) === undefined &&
                ((documents[0].drivingBanR1s &&
                  documents[0].drivingBanR1s.length > 0 &&
                  getOngoingDrivingBan(documents[0].drivingBanR1s) === undefined) ||
                  !documents[0].drivingBanR1s ||
                  documents[0].drivingBanR1s.length < 1)))
          ? fm('general.no')
          : ''}
    </>
  );
};

const convertBGToMeasurements = (bgDocs: Array<{ [key: string]: any } & IControlProps>): IMeasurement[] => {
  const measurements: Partial<IMeasurement>[] = [];
  bgDocs.forEach((bg) => {
    if (!bg.date) return;
    if (bg.weight) {
      measurements.push({ date: bg.date, value: `${bg.weight}`, code: fieldNameToCodeString('weight') || '' });
    }
    if (bg.height) {
      measurements.push({ date: bg.date, value: `${bg.height}`, code: fieldNameToCodeString('height') || '' });
    }
    if (bg.bmi) {
      measurements.push({ date: bg.date, value: `${bg.bmi}`, code: fieldNameToCodeString('bmi') || '' });
    }
  });
  return measurements as IMeasurement[];
};

const BackgroundDash = ({ documents }: IOwnProps): JSX.Element | undefined => {
  const platform = useSelector((s: IState) => s.session.platforms?.selected);

  // My background documents
  const myBgDocs =
    useSelector((s: IState) => s.myapp.sortedAndMergedDocuments)?.filter((d) =>
      d._type.toLowerCase().includes('background'),
    ) ?? [];

  const myMeasurements = convertBGToMeasurements(myBgDocs);

  // All background documents
  const backgroundDocs: IBackground[] = documents
    .filter((d) => d._type === 'background')
    .concat(myBgDocs) as IBackground[];
  const measurementDocs: IMeasurement[] = documents.filter((d) => d._type === 'measurement').concat(myMeasurements);

  const weight = getLatestMeasurementValueAndDate('weight', measurementDocs);
  const height = getLatestMeasurementValueAndDate('height', measurementDocs);
  const headCircumference = getLatestMeasurementValueAndDate('headCircumference', measurementDocs);
  const bmi = getLatestMeasurementValueAndDate('bmi', measurementDocs);

  const tileData = [];
  if (platform === 'sma' || platform === 'dmd') {
    weight &&
      tileData.push({
        date: isPartialDate(weight.date) ? formatPartialDate(weight.date) : '',
        title: fm('background.weight'),
        value: <FormattedMessage id="background.kg" values={{ value: weight.value }} />,
        key: 'weight',
      });
    height &&
      tileData.push({
        date: isPartialDate(height.date) ? formatPartialDate(height.date) : '',
        title: fm('background.height'),
        value: <FormattedMessage id="background.cm" values={{ value: height.value }} />,
        key: 'height',
      });
    headCircumference &&
      tileData.push({
        date: isPartialDate(headCircumference.date) ? formatPartialDate(headCircumference.date) : '',
        title: fm('background.headCircumference'),
        value: <FormattedMessage id="background.cm" values={{ value: headCircumference.value }} />,
        key: 'headCircumference',
      });
  }

  if (platform === 'ninmt') {
    const gaf = getLatestMeasurementValueAndDate('gaf', measurementDocs);
    const madrs = getLatestMeasurementValueAndDate('madrs', measurementDocs);
    const ybocs = getLatestMeasurementValueAndDate('ybocs', measurementDocs);

    gaf &&
      tileData.push({
        date: isPartialDate(gaf.date) ? formatPartialDate(gaf.date) : '',
        title: fm('background.gaf'),
        value: gaf.value,
        key: 'gaf',
      });
    madrs &&
      tileData.push({
        date: isPartialDate(madrs.date) ? formatPartialDate(madrs.date) : '',
        title: fm('background.madrs'),
        value: madrs.value,
        key: 'madrs',
      });
    ybocs &&
      tileData.push({
        date: isPartialDate(ybocs.date) ? formatPartialDate(ybocs.date) : '',
        title: fm('background.ybocsTitle'),
        value: ybocs.value,
        key: 'ybocs',
      });
  }

  if (platform === 'mgravis') {
    weight &&
      tileData.push({
        date: isPartialDate(weight.date) ? formatPartialDate(weight.date) : '',
        title: fm('background.weight'),
        value: <FormattedMessage id="background.kg" values={{ value: weight.value }} />,
        key: 'weight',
      });
    height &&
      tileData.push({
        date: isPartialDate(height.date) ? formatPartialDate(height.date) : '',
        title: fm('background.height'),
        value: <FormattedMessage id="background.cm" values={{ value: height.value }} />,
        key: 'height',
      });
    const smokingStatus = getLatestValue(backgroundDocs, 'smoking');
    smokingStatus &&
      tileData.push({
        date: isPartialDate(smokingStatus?.date) ? formatPartialDate(smokingStatus.date) : '',
        title: fm('background.smoking'),
        value: fm(`background.opts.smoking.${smokingStatus.value}`),
        key: 'smoking',
      });
    const snusStatus = getLatestValue(backgroundDocs, 'snus');
    snusStatus &&
      tileData.push({
        date: isPartialDate(snusStatus?.date) ? formatPartialDate(snusStatus.date) : '',
        title: fm('background.snusTitle'),
        value: fm(`background.opts.snus.${snusStatus.value}`),
        key: 'snus',
      });
    const employment = getLatestValue(backgroundDocs, 'employment');
    employment &&
      tileData.push({
        date: isPartialDate(employment?.date) ? formatPartialDate(employment.date) : '',
        title: fm('background.employment'),
        value: employment.value?.map((e: string, i: number) => (
          <div key={i + e}>{fm(`background.opts.employment.${e}`)}</div>
        )),
        key: 'employment',
      });
    const workAbilityScore = getLatestValue(backgroundDocs, 'workAbilityScore');
    if (
      (employment?.value?.includes('partTime') || employment?.value?.includes('fullTime')) &&
      (workAbilityScore?.value?.workAbilityComparedToLifetimeBest ||
        workAbilityScore?.value?.workAbilityComparedToLifetimeBest === 0)
    ) {
      tileData.push({
        date: isPartialDate(employment?.date) ? formatPartialDate(employment.date) : '',
        title: fm('background.workAbility'),
        value: `${workAbilityScore?.value?.workAbilityComparedToLifetimeBest} / 10`,
        key: 'workAbilityScore',
      });
    }
  }

  return (
    <React.Fragment>
      <PlatformConditional platform={['sma', 'dmd', 'ninmt', 'mgravis']}>
        <TileContentMaker type="specificList" data={tileData} />
      </PlatformConditional>

      <PlatformConditional platform={['ms']}>
        <TileContentMaker
          type="specificList"
          data={[
            ...concat(['height'], chooseBetweenWeightAndBmi(weight, bmi)).map((row) => {
              const date =
                row === 'height' ? height?.date : row === 'bmi' ? bmi?.date : row === 'weight' ? weight?.date : null;
              const value =
                row === 'height' ? height?.value : row === 'bmi' ? bmi?.value : row === 'weight' ? weight?.value : null;

              return {
                date: isPartialDate(date) ? formatPartialDate(date) : '',
                title: fm(`background.${row}`),
                value:
                  row === 'bmi'
                    ? value
                    : value
                      ? fm(`background.${row === 'height' ? 'cm' : 'kg'}`, {
                          value: value,
                        })
                      : '',
                key: row,
              };
            }),
            ...(getLatestValue(backgroundDocs, 'smoking')
              ? [
                  {
                    date: isPartialDate(getLatestValue(backgroundDocs, 'smoking')?.date)
                      ? formatPartialDate(getLatestValue(backgroundDocs, 'smoking')?.date)
                      : '',
                    title: fm('background.smoking'),
                    value: fm(`background.opts.smoking.${getLatestValue(backgroundDocs, 'smoking')?.value}`),
                    key: 'smoking',
                  },
                ]
              : []),
          ]}
        />

        <TileContentMaker
          type="specificListNoDate"
          data={[
            ...(getLatestValue(backgroundDocs, 'reimbursementValidUntil') &&
            isPartialDate(getLatestValue(backgroundDocs, 'reimbursementValidUntil')?.value)
              ? [
                  {
                    title: fm('background.reimbursement'),
                    value: fm('background.dueDate', {
                      value: formatPartialDate(getLatestValue(backgroundDocs, 'reimbursementValidUntil')?.value),
                    }),
                    key: 'reimbursement',
                  },
                ]
              : []),
            ...(getLatestValue(backgroundDocs, 'employment') &&
            Array.isArray(getLatestValue(backgroundDocs, 'employment')?.value) &&
            getLatestValue(backgroundDocs, 'employment')?.value.length > 0
              ? [
                  {
                    title: fm('background.employment'),
                    value:
                      getLatestValue(backgroundDocs, 'employment')?.value.length > 1
                        ? fm('background.multiple')
                        : fm(`background.opts.employment.${getLatestValue(backgroundDocs, 'employment')?.value}`),
                    key: 'employment',
                  },
                ]
              : []),
            ...(getLatestValue(backgroundDocs, 'typeOfLiving')
              ? [
                  {
                    title: fm('background.typeOfLiving'),
                    value: fm(`background.opts.typeOfLiving.${getLatestValue(backgroundDocs, 'typeOfLiving')?.value}`),
                    key: 'typeOfLiving',
                  },
                ]
              : []),
          ]}
        />
      </PlatformConditional>

      <PlatformConditional platform={['parkinson']}>
        <TileContentMaker
          type="specificList"
          data={[
            ...concat(['height'], chooseBetweenWeightAndBmi(weight, bmi)).map((row) => {
              const date =
                row === 'height' ? height?.date : row === 'bmi' ? bmi?.date : row === 'weight' ? weight?.date : null;
              const value =
                row === 'height' ? height?.value : row === 'bmi' ? bmi?.value : row === 'weight' ? weight?.value : null;

              return {
                date: isPartialDate(date) ? formatPartialDate(date) : '',
                title: fm(`background.${row}`),
                value:
                  row === 'bmi'
                    ? value
                    : value
                      ? fm(`background.${row === 'height' ? 'cm' : 'kg'}`, {
                          value: value,
                        })
                      : '',
                key: row,
              };
            }),
          ]}
        />
        <TileContentMaker
          type="specificListNoDate"
          data={[
            ...(getLatestValue(backgroundDocs, 'employment') &&
            Array.isArray(getLatestValue(backgroundDocs, 'employment')?.value) &&
            getLatestValue(backgroundDocs, 'employment')?.value.length > 0
              ? [
                  {
                    title: fm('background.employment'),
                    value:
                      getLatestValue(backgroundDocs, 'employment')?.value.length > 1
                        ? fm('background.multiple')
                        : fm(`background.opts.employment.${getLatestValue(backgroundDocs, 'employment')?.value}`),
                    key: 'employment',
                  },
                ]
              : []),
            ...(getLatestValue(backgroundDocs, 'typeOfLiving')
              ? [
                  {
                    title: fm('background.typeOfLiving'),
                    value: fm(`background.opts.typeOfLiving.${getLatestValue(backgroundDocs, 'typeOfLiving')?.value}`),
                    key: 'typeOfLiving',
                  },
                ]
              : []),
          ]}
        />
      </PlatformConditional>
      <PlatformConditional platform={['huntington']}>
        <TileContentMaker
          type="specificListNoDate"
          data={[
            {
              title: fm(`background.familyAnamnesis`),
              value:
                Array.isArray(getLatestValue(backgroundDocs, 'familyMembersAnamnesisHuntington')?.value) &&
                getLatestValue(backgroundDocs, 'familyMembersAnamnesisHuntington')?.value?.length > 0
                  ? getLocalizedValue(
                      'relatives',
                      getLatestValue(backgroundDocs, 'familyMembersAnamnesisHuntington')?.value,
                    )
                  : getLocalizedValue(
                      'yesNoUnknown',
                      getLatestValue(backgroundDocs, 'familyAnamnesisHuntington')?.value,
                    ),
              key: 'familyAnamnesis',
            },
            {
              title: fm(`background.maritalStatus`),
              value: getLocalizedValue('maritalStatus', getLatestValue(backgroundDocs, 'maritalStatus')?.value),
              key: 'maritalStatus',
            },
            {
              title: fm(`background.typeOfLiving`),
              value: getLocalizedValue('typeOfLiving', getLatestValue(backgroundDocs, 'typeOfLiving')?.value),
              key: 'typeOfLiving',
            },
            {
              title: fm(`background.employment`),
              value: getLocalizedValue('employment', getLatestValue(backgroundDocs, 'employment')?.value),
              key: 'employment',
            },
          ]}
        />
      </PlatformConditional>
      <PlatformConditional platform={['epilepsy']}>
        {isPartialDate(weight?.date) ? (
          <TileContentMaker
            type="specificList"
            data={[
              {
                date: formatPartialDate(weight?.date),
                ...{
                  title: fm(`background.weight`),
                  value: weight?.value ? weight?.value + ' kg' : fm('general.noData'),
                  key: 'weight',
                },
              },
            ]}
          />
        ) : (
          <TileContentMaker
            type="specificListNoDate"
            data={[
              {
                title: fm(`background.weight`),
                value: weight?.value ? weight?.value + ' kg' : fm('general.noData'),
                key: 'weight',
              },
            ]}
          />
        )}
        <TileContentMaker
          type="specificListNoDate"
          data={[
            ...(getLatestValue(backgroundDocs, 'employment') &&
            Array.isArray(getLatestValue(backgroundDocs, 'employment')?.value) &&
            getLatestValue(backgroundDocs, 'employment')?.value.length > 0
              ? [
                  {
                    title: fm('background.employment'),
                    value:
                      getLatestValue(backgroundDocs, 'employment')?.value.length > 1
                        ? fm('background.multiple')
                        : fm(`background.opts.employment.${getLatestValue(backgroundDocs, 'employment')?.value}`),
                    key: 'employment',
                  },
                ]
              : []),
            ...(getLatestValue(backgroundDocs, 'typeOfLiving')
              ? [
                  {
                    title: fm('background.typeOfLiving'),
                    value: fm(`background.opts.typeOfLiving.${getLatestValue(backgroundDocs, 'typeOfLiving')?.value}`),
                    key: 'typeOfLiving',
                  },
                ]
              : []),
            ...(getLatestValue(backgroundDocs, 'driversLicense')
              ? [
                  {
                    title: fm('background.driversLicense'),
                    value: (
                      <>
                        {backgroundDocs &&
                        backgroundDocs[0].driversLicenseGroup &&
                        backgroundDocs[0].driversLicenseGroup.includes('R1')
                          ? fm('background.drivingBanR1Short')
                          : ''}
                        {backgroundDocs &&
                        backgroundDocs[0].driversLicenseGroup &&
                        backgroundDocs[0].driversLicenseGroup.includes('R1') &&
                        backgroundDocs[0].driversLicenseGroup.includes('R2') ? (
                          <span> {fm('general.and')} </span>
                        ) : (
                          ''
                        )}
                        {backgroundDocs &&
                        backgroundDocs[0].driversLicenseGroup &&
                        backgroundDocs[0].driversLicenseGroup.includes('R2')
                          ? fm('background.drivingBanR2Short')
                          : ''}
                        {backgroundDocs &&
                        (!backgroundDocs[0].driversLicenseGroup || backgroundDocs[0].driversLicenseGroup.length < 1)
                          ? backgroundDocs[0].driversLicense === 'yes'
                            ? fm('general.yes')
                            : backgroundDocs[0].driversLicense === 'no'
                              ? fm('general.no')
                              : backgroundDocs[0].driversLicense === 'unknown'
                                ? fm('general.unknown')
                                : ''
                          : ''}
                        {backgroundDocs && !backgroundDocs[0].driversLicense ? fm('general.noData') : ''}
                      </>
                    ),
                    key: 'driversLicense',
                  },
                ]
              : []),
            ...(getLatestValue(backgroundDocs, 'driversLicense')
              ? [
                  {
                    title: fm('background.ongoingDrivingBan'),
                    value: checkDrivingBans(backgroundDocs),
                    key: 'ongoingDrivingBan',
                  },
                ]
              : []),
          ]}
        />
      </PlatformConditional>
      <PlatformConditional platform="sleepApnea">
        <TileContentMaker
          type="specificListNoDate"
          data={[
            ...(getLatestValue(backgroundDocs, 'driversLicense')
              ? [
                  {
                    title: fm('background.driversLicense'),
                    value: (
                      <>
                        {backgroundDocs &&
                        backgroundDocs[0].driversLicenseGroup &&
                        backgroundDocs[0].driversLicenseGroup.includes('R1')
                          ? fm('background.drivingBanR1Short')
                          : ''}
                        {backgroundDocs &&
                        backgroundDocs[0].driversLicenseGroup &&
                        backgroundDocs[0].driversLicenseGroup.includes('R1') &&
                        backgroundDocs[0].driversLicenseGroup.includes('R2') ? (
                          <span> {fm('general.and')} </span>
                        ) : (
                          ''
                        )}
                        {backgroundDocs &&
                        backgroundDocs[0].driversLicenseGroup &&
                        backgroundDocs[0].driversLicenseGroup.includes('R2')
                          ? fm('background.drivingBanR2Short')
                          : ''}
                        {backgroundDocs &&
                        (!backgroundDocs[0].driversLicenseGroup || backgroundDocs[0].driversLicenseGroup.length < 1)
                          ? backgroundDocs[0].driversLicense === 'yes'
                            ? fm('general.yes')
                            : backgroundDocs[0].driversLicense === 'no'
                              ? fm('general.no')
                              : backgroundDocs[0].driversLicense === 'unknown'
                                ? fm('general.unknown')
                                : ''
                          : ''}
                        {backgroundDocs && !backgroundDocs[0].driversLicense ? fm('general.noData') : ''}
                      </>
                    ),
                    key: 'driversLicense',
                  },
                ]
              : []),
            ...(getLatestValue(backgroundDocs, 'driversLicense')
              ? [
                  {
                    title: fm('background.ongoingDrivingBan'),
                    value: checkDrivingBans(backgroundDocs),
                    key: 'ongoingDrivingBan',
                  },
                ]
              : []),
            ...(getLatestValue(backgroundDocs, 'driversLicense')
              ? [
                  {
                    title: fm('background.noProDrivingRequirements'),
                    value: (
                      <>
                        {backgroundDocs &&
                          backgroundDocs[0].noProDrivingRequirements?.map((d, i) =>
                            isOngoingTimespan(d.startDate, d.endDate) ? (
                              i === 0 ? (
                                <React.Fragment key={i + 'R1outer'}>
                                  <div key={i + d.id + 'R1start'}>
                                    {fm('background.fromShort')} {d.startDate ? formatPartialDate(d.startDate) : '-'}
                                  </div>
                                </React.Fragment>
                              ) : (
                                <span key={i + 'emptyR1'}></span>
                              )
                            ) : (
                              ''
                            ),
                          )}
                        {(backgroundDocs && !backgroundDocs[0].noProDrivingRequirements) ||
                        (backgroundDocs &&
                          backgroundDocs[0].noProDrivingRequirements &&
                          backgroundDocs[0].noProDrivingRequirements.length < 1)
                          ? fm('general.noData')
                          : ''}
                      </>
                    ),
                    key: 'noProDrivingRequirements',
                  },
                ]
              : []),
          ]}
        />
      </PlatformConditional>
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<IMeasurement | IBackground>;
}

export default BackgroundDash;
